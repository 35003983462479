.menu-wrap-master {
  position: relative;
  margin: auto;
}

.menu-wrap ul, .menu-wrap-master ul {
  list-style: none;
}

.menu-wrap ul li a {
  margin: 0 !important;
  display: block;
  text-align: center;
  text-decoration: none;
}

.menu-wrap-master ul li a {
  float: left;
  display: block;
  text-align: center;
  text-decoration: none;
}

@media (max-width: 410px) {
  .menu-wrap ul li a {
    float: right;
  }
}

.logo {
  max-width: 200px;
}

.item_1 > a {
  text-transform: uppercase;
  color: #646464;
  height: 34px;
  border-radius: 40px;
  border: 1px solid #646464;
  width: 158px;
  line-height: 34px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
}

.item_1 > a:hover, .item_2 > a:hover, .item_3 > a:hover {
  color: #fff;
  border: 1px solid #a42187;
  background: #a42187;
}

.item_2 > a {
  text-transform: uppercase;
  color: #00a8e1;
  height: 34px;
  border-radius: 40px;
  border: 1px solid #00a8e1;
  width: 158px;
  line-height: 34px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
}

.item_3 > a {
  text-transform: uppercase;
  color: #646464;
  height: 34px;
  border-radius: 40px;
  border: 1px solid #646464;
  width: 111px;
  line-height: 34px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
}

.item_5 > a {
  text-transform: uppercase;
  color: #fff;
  background: #00aae3;
  height: 34px;
  border-radius: 40px;
  border: 1px solid #00aae3;
  width: 158px;
  line-height: 34px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
  margin-right: 135px;
}

@media (max-width: 991px) {
  .item_5 > a {
    margin-right: 0;
  }
}

.item_4 > a {
  text-transform: uppercase;
  color: #fff;
  background: #a62189;
  height: 34px;
  border-radius: 40px;
  border: 1px solid #a62189;
  width: 158px;
  line-height: 34px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
}

.item_5 > a:hover {
  border: 1px solid #007fa9;
  background: #007fa9;
}

.item_4 > a:hover {
  border: 1px solid #7c1866;
  background: #7c1866;
}

.item_33 > a {
  width: 215px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 40px;
  padding: 19px 0;
  background: #a62189;
}

.item_55 > a {
  width: 215px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 40px;
  padding: 19px 0;
  background: #00aae3;
  margin-right: 20px;
}

.menu-wrap > ul > li > a {
  margin-left: 24px;
}

@media (max-width: 410px) {
  .menu-wrap > ul > li > a {
    margin-bottom: 24px;
  }
}

.menu-wrap > ul > li > a:hover, .menu-wrap-master > ul > li > a:hover {
  display: block;
}

.menu-wrap ul ul li a:hover, .menu-wrap-master ul ul li a:hover {
  border: 1px solid;
  border-radius: 40px;
  display: block;
}

.menu-wrap ul ul li:nth-child(7) a, .menu-wrap-master ul ul li:nth-child(7) a {
  padding: 0;
  border: none;
  display: block;
}

.menu-wrap ul li:hover ul, .menu-wrap-master ul li:hover ul {
  display: block;
}

.menu-wrap ul ul {
  z-index: 99;
  display: none;
  list-style: none;
  position: absolute;
  right: 0;
  top: 34px;
  width: 230px;
}

.menu-wrap-master ul ul {
  z-index: 99;
  display: none;
  list-style: none;
  position: absolute;
  left: 0;
  top: 34px;
  width: 100%;
}

@media (max-width: 991px) {
  .menu-wrap ul ul {
    left: -40px;
  }
}

@media (max-width: 479px) {
  .menu-wrap-master ul ul {
    left: 0;
    top: 111px;
  }
  .item_55 > a {
    margin-bottom: 10px;
  }
}

.menu-wrap ul li {
  display: inline;
}

.menu-wrap-master > ul > li {
  display: inline;
}

.menu_block {
  left: 0;
  background-color: #a62189;
  margin-top: 10px;
  border-radius: 20px;
  padding: 6px 10px 11px 8px;
}

.menu_block-master {
  background-color: #a62189;
  margin-top: 30px;
  border-radius: 20px;
  padding: 6px 10px 11px 8px;
}

.menu-wrap ul ul li a {
  float: none;
  display: block;
  padding: 2px 10px;
  margin: 4px 0;
  border: 1px solid transparent;
  border-radius: 40px;
  text-align: left;
  width: 100%;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
  color: #fff;
}

.menu-wrap-master ul ul li a {
  float: none;
  display: block;
  padding: 4px 10px;
  margin: 4px 0;
  border: 1px solid #fff;
  border-radius: 40px;
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
  color: #fff;
}

.menu-wrap ul ul li a:hover, .menu-wrap-master ul ul li a:hover {
  color: #fff;
}

.icon {
  i {
    font-size: 35px;
  }
}

.icon_menu {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  vertical-align: middle;
  background: url("../images/menu_icon.svg") no-repeat;
}

.icon_menu:hover {
  background: url("../images/menu_icon_white.svg") no-repeat;
}

.icon_home {
  background-position: 0 0;
}

.icon_adv {
  background-position: -20px 0;
}

.icon_works {
  background-position: -40px 0;
}

.icon_cameras {
  background-position: -60px 0;
}

.icon_prices {
  background-position: -80px 0;
}

.icon_case {
  background-position: 0 -20px;
}

.icon_shop {
  background-position: -20px -20px;
}

.icon_partners {
  background-position: -40px -20px;
}

.icon_contacts {
  background-position: -60px -20px;
}

.icon_shop_hover {
  background: #fff;
  width: 70%;
  color: #a42187;
  padding: 3px 10px;
  border-radius: 30px;
  transition: width 0.7s;
  text-transform: uppercase;
}

.icon_shop_hover:hover {
  width: 100%;
}

.icon-master a {
  border: 1px solid #fff;
  border-radius: 30px;
}

.icon-master a:hover {
  background: #fff;
}

.icon-master a span:hover {
  color: #a42187;
}

.note-master {
  margin: 10px 0 0 10px;
  font-size: 12px;
}

.paginat {
  text-align: right;
}

.brand {
  color: #292929;
  font-size: 2rem;
  font-weight: bold;
}

.dropdown {
  text-transform: uppercase;
  color: #646464;
  border-radius: 40px;
  border: 1px solid #646464;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;

  a {
    padding: 5px 25px;
    text-transform: none;
  }
}

.bbtn {
  text-transform: uppercase;
  color: #646464;
  border-radius: 40px;
  border: 1px solid #646464;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  min-width: 120px;
  justify-content: center;
  padding: 10px 20px !important;

  &.bbtn-reg {
    border-color: #00a8e1;
    color: #00a8e1;
  }
}